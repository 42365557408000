<template>
  <div class="filters">
    <div class="filters__container">
      <UniversalSelect
        v-model="filters.application_id"
        style="min-width: 180px;"
        :options="appsOptions"
        placeholder="App"
        title="App"
        :showSearch="true"
        :disabled="loading || disabled"
        @change="changeAppFilter"
      />
      <UniversalSelect
        v-model="filters.min_app_version"
        style="min-width: 180px;"
        :options="appVersionsOptions"
        placeholder="Version"
        title="Available from (version)"
        :showSearch="true"
        :disabled="loading || disabled"
        @change="changeAppVersionFilter"
      />
      <div class="filters__search">
        <a-input-search
          v-model="filters.search"
          placeholder="Input search text"
          size="large"
          allowClear
          enter-button
          @search="changeSearchFilter"
        />
      </div>
    </div>
  </div>
</template>

<script>
import UniversalSelect from '@/components/custom/filters/universalSelect.vue'

export default {
  props: {
    appsOptions: {
      type: Array,
      default: () => [],
    },
    appVersionsOptions: {
      type: Array,
      default: () => [],
    },
    search: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    UniversalSelect,
  },
  data: () => ({
    filters: {
      application_id: undefined,
      min_app_version: undefined,
      search: '',
    },
    page: 1,
    limit: 10,
  }),
  methods: {
    setFilters(newFilters) {
      for (const [key] of Object.entries(this.filters)) {
        if (newFilters[key] !== undefined) {
          if (Number(newFilters[key])) {
            this.filters[key] = Number(newFilters[key])
          } else {
            this.filters[key] = newFilters[key]
          }
        } else {
          this.filters[key] = undefined
        }
      }
      if (newFilters['page']) {
        this.page = newFilters['page']
      }
      if (newFilters['limit']) {
        this.limit = newFilters['limit']
      }
    },
    changeAppFilter() {
      this.clearPagination()
      this.sendFiltersEvent()
    },
    changeAppVersionFilter() {
      this.clearPagination()
      this.sendFiltersEvent()
    },
    changeSearchFilter() {
      this.clearPagination()
      this.sendFiltersEvent()
    },
    clearPagination() {
      this.page = 1
      this.limit = 10
    },
    setQueryParams() {
      let queryParams = ''
      for (const [key, value] of Object.entries(this.filters)) {
        if (typeof value === 'string' && !!value) {
          queryParams = this.addQueryParam(queryParams, key, value)
        }
        if (value !== undefined && typeof value !== 'string') {
          queryParams = this.addQueryParam(queryParams, key, value)
        }
      }

      if (this.page !== 1) {
        queryParams = this.addQueryParam(queryParams, 'page', this.page)
      }
      if (this.limit !== 10) {
        queryParams = this.addQueryParam(queryParams, 'limit', this.limit)
      }

      if (this.$route.fullPath === `${this.$route.path}${queryParams}`) {
        return
      }

      this.$router.push(`${this.$route.path}${queryParams}`)
    },
    addQueryParam(queryParams, key, value) {
      const str = queryParams + `${queryParams.length ? '&' : '?'}${key}=${value}`
      return str
    },
    getFilters() {
      this.setQueryParams()
      return {
        application_id: this.filters.application_id,
        min_app_version: this.filters.min_app_version,
        search: this.filters.search,
      }
    },
    sendFiltersEvent() {
      const filters = this.getFilters()
      this.$emit('change', filters)
    },
    resetFilters() {
      this.filters = {
        application_id: undefined,
        min_app_version: undefined,
        search: '',
      }
      this.$router.push(`${this.$route.path}`)
    },
  },
}
</script>

<style lang="scss">
.filters {
  display: flex;
  flex-direction: column;
  gap: 10px;
  &__container {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  &__search {
    align-self: end;
    justify-content: flex-end;
    display: flex;
    width: 100%;
    & .ant-input-search {
      max-width: 500px;
    }
  }
}
</style>
