<template>
  <a-modal
    v-model="visible"
    title="Import deeplinks"
    :destroyOnClose="true"
    @cancel="closeModal"
  >
    <div class="import-modal">
      <div class="import-modal__text-container">
        <a-tooltip
          placement="top"
          arrow-point-at-center
          :overlayStyle="{ 'white-space': 'normal', 'min-width': '350px' }"
          :get-popup-container="getPopupContainer"
        >
          <template slot="title">
            <div class="d-flex flex-column">
              <span><b>To obtain JSON files:</b></span>
              <ul class="import-modal__list">
                <li>Open the iOS app and go to the “Deeplinks” section on the tech screen in Bet, KZ, or Com</li>
                <li>Share as a file</li>
                <li>Upload the .json files here</li>
              </ul>
            </div>
          </template>
          <a-icon class="d-flex font-size-18" type="info-circle" />
        </a-tooltip>
        <span>Supports up to 7 JSON files</span>
      </div>
      <a-upload
        :multiple="true"
        :file-list="deeplinkList"
        :disabled="disbaledUpload"
        action="#"
        :showUploadList="{
          showPreviewIcon: true,
          showRemoveIcon: true,
        }"
        @change="handleChange"
      >
        <a-button> <a-icon type="upload" /> Upload </a-button>
      </a-upload>
      <div v-if="!deeplinkList.length" class="import-modal__empty">
        The list of downloaded files is empty
      </div>
      <div v-else class="import-modal__info">
        <div class="import-modal__info__icon">
          <a-icon type="info-circle" class="text-danger" />
        </div>
        <span>By clicking 'Import', you confirm that <b>all existing records will be deleted</b> and replaced with those in the uploaded files.</span>
      </div>
    </div>
    <template slot="footer">
      <a-button @click="closeModal">
        Cancel
      </a-button>
      <a-button
        :type="actionButton.type"
        :loading="loading"
        :disabled="loading || !deeplinkList.length"
        @click="action"
      >
        {{ actionButton.text }}
      </a-button>
    </template>
  </a-modal>
</template>

<script>

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    actionButton: {
      type: Object,
      default: () => ({
        text: 'Confirm',
        type: 'primary',
      }),
    },
  },
  data: () => ({
    visible: false,
    deeplinkList: [],
  }),
  watch: {
    show: {
      handler(val) {
        if (val) {
          this.visible = true
        } else {
          this.visible = false
          this.deeplinkList = []
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    disbaledUpload() {
      return this.deeplinkList?.length > 6
    },
  },
  methods: {
    getPopupContainer(trigger) {
      return trigger.parentElement
    },
    handleChange(info) {
      let fileList = [...info.fileList]

      // 1. Limit the number of uploaded files
      //    Only to show seven recent uploaded files, and old ones will be replaced by the new
      fileList = fileList.slice(-7)

      fileList = fileList.map(file => {
        file.status = 'done'
        file.response = { status: 'success' }
        return file
      })

      this.deeplinkList = fileList
    },
    action() {
      this.$emit('action', this.deeplinkList)
    },
    closeModal() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss">
.import-modal {
  display: flex;
  justify-content: center;
  flex-direction: column;
  &__text-container {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
  }
  &__list {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    margin: 10px 0;
    gap: 3px;
  }
  & .ant-upload-list-item {
    height: auto;
    padding: 5px;
    display: flex;
    align-items: center;
    border: 1px solid #e4e9f0;
    border-radius: 4px;
  }
  & .ant-upload-list-item-info {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    padding: 0;
    & > span {
      display: flex;
      align-items: center;
    }
    & .anticon-paper-clip {
      top: 50%;
      transform: translateY(-50%);
    }
  }
  & .ant-upload-list-item:hover .ant-upload-list-item-info {
    background-color: transparent;
  }
  &__empty {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    max-height: 100px;
  }
  &__info {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0 0;
    gap: 10px;
    &__icon {
      display: flex;
      align-self: flex-start;
      margin-top: 3px;
    }
  }
}
</style>
